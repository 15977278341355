import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["quantity"];

  connect() {
    window.dataLayer = window.dataLayer || [];
  }

  addToCart() {
    let quantity = 1;

    if (this.hasQuantityTarget && this.quantityTarget instanceof HTMLInputElement) {
      quantity = +this.quantityTarget.value || 1;
    }

    const itemName = this.element.dataset.itemName;
    const itemId = this.element.dataset.itemId;
    const itemCategory = this.element.dataset.itemCategory;

    window.dataLayer.push({
      event: "add_to_cart",
      ecommerce: {
        items: [
          {
            item_name: itemName,
            item_id: itemId,
            item_category: itemCategory,
            quantity: quantity
          }
        ]
      }
    });
    this.changeIcon()
  }

  changeIcon() {
    // Hide plus-box on product add (not inside the turbo frame anymore)
    let plusBox = this.element.closest('div').querySelector('.plus-box')
    plusBox.classList.add("added-to-truck")
    setTimeout(() => {
      plusBox.classList.remove("added-to-truck")
    }, 3000);
  }
}
